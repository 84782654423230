import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentView } from "@ds/hooks/use-current-view";
import { useVisibility } from "@ds/hooks/use-visibility";

import { EntityTypeName } from "@ds/model/entity-model";

import { getMainTableDataTypeByRoute, getPageTitleByTableDataType } from "@ds/modules/table-data/utils/helpers";

import { MainView } from "@ds/components/main-view";
import { TableTabMenu } from "@ds/components/table/table-tab-menu";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";

import { UploadFilesModal } from "../components/common/upload-files-modal";
import { ContentAssetsTableActionsMenu } from "./components/common/content-assets-table-actions-menu";
import { ContentAssetsTable } from "./components/conent-assets-table";
import { ContentAssetInfo } from "./components/content-asset-info";
import { ContentAssetsGrid } from "./components/content-assets-grid";
import { UpdateContentAssetModal } from "./components/forms/update-content-asset-modal";
import { ContentAssetsLayout } from "./components/types";
import {
  selectAggregatedContentAssetForSelectedEntities,
  selectContentAssetsIsSucceeded,
} from "./redux/selectors/common-selectors";
import { ContentAssetsRemoteOperation, contentAssetsActions } from "./redux/slice";
import { getApiContentTypeByTableType, getEntitySubtypeByTableType } from "./utils/helpers";

export const ContentAssetsMainPage: FC = () => {
  const { pathname } = useLocation();

  const [currentView] = useCurrentView();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useVisibility();
  const isSucceeded = useRootSelector(state =>
    selectContentAssetsIsSucceeded(state, ContentAssetsRemoteOperation.THUMBNAILS),
  );

  const filterOptions = useMemo(() => {
    const currentTableType = getMainTableDataTypeByRoute(pathname);
    return {
      entityType: EntityTypeName.CONTENT_ASSET,
      entitySubtype: getEntitySubtypeByTableType(currentTableType),
      tableType: currentTableType,
      queryFilters: { contentType: getApiContentTypeByTableType(currentTableType) },
    };
  }, [pathname]);

  const aggregatedAsset = useDeepRootSelector(state =>
    selectAggregatedContentAssetForSelectedEntities(state, filterOptions.tableType),
  );

  const [currentLayout, setCurrentLayout] = useState(ContentAssetsLayout.LIST);

  useEffect(() => {
    if (isSucceeded) {
      setIsUpdateModalVisible(false)();
    }
  }, [isSucceeded, setIsUpdateModalVisible]);

  const onSwitchLayoutHandler = useCallback(
    (newLayout: ContentAssetsLayout) => () =>
      setCurrentLayout(newLayout === ContentAssetsLayout.TILES ? ContentAssetsLayout.TILES : ContentAssetsLayout.LIST),
    [setCurrentLayout],
  );

  const dispatch = useRootDispatch();
  const onUploadClickHandler = useCallback(
    (files: File[]) => {
      if (files.length) {
        dispatch(contentAssetsActions.uploadContentAssets(files, filterOptions.tableType));
      }
    },
    [dispatch, filterOptions.tableType],
  );

  const setIsMinimized = useCallback(
    (value: boolean) => () => dispatch(contentAssetsActions.setUploadModalMinimized(value)),
    [dispatch],
  );

  if (!currentView) {
    return null;
  }

  return (
    <>
      <MainView
        title={getPageTitleByTableDataType(filterOptions.tableType)}
        filterOptions={filterOptions}
        options={<TableTabMenu tableType={filterOptions.tableType} currentView={currentView} />}
        view={
          currentLayout === ContentAssetsLayout.LIST ? (
            <ContentAssetsTable tableType={filterOptions.tableType} onUpload={onUploadClickHandler} />
          ) : (
            <ContentAssetsGrid tableType={filterOptions.tableType} onUpload={onUploadClickHandler} />
          )
        }
        info={<ContentAssetInfo tableType={filterOptions.tableType} onUpload={onUploadClickHandler} />}
        actions={
          <ContentAssetsTableActionsMenu
            filterOptions={filterOptions}
            layout={currentLayout}
            onUpload={onUploadClickHandler}
            onUpdate={setIsUpdateModalVisible(true)}
            onSwitchLayout={onSwitchLayoutHandler}
          />
        }
      />

      <UploadFilesModal tableType={filterOptions.tableType} setIsMinimized={setIsMinimized} />

      {isUpdateModalVisible && aggregatedAsset && (
        <UpdateContentAssetModal
          tableType={filterOptions.tableType}
          initialValue={aggregatedAsset}
          onClose={setIsUpdateModalVisible(false)}
        />
      )}
    </>
  );
};
