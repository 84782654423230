import { produce } from "immer";
import { type SetOptional } from "type-fest";

import { normalizeBase } from "@ds/model/helpers";

import { normalize as assetNormalize } from "@ds/modules/content/assets/utils/normalizer";

import { isApiPlaylistAsset, isPlaylistAssetArray, isPlaylistAssetQueryOutput } from "./model";

const normalizer = (entity: ApiPlaylistAsset) =>
  produce(normalizeBase(entity), (draft: ApiPlaylistAsset) => {
    draft.disabled = entity.disabled || undefined;
    draft.cycles = entity.cycles || undefined;
    draft.duration = entity.duration || undefined;
    draft.asset_id = entity.asset_id || undefined;
    draft.asset = assetNormalize(entity.asset);
  });

export const normalize = ((
  entityOrEntities: ApiPlaylistAsset | ApiPlaylistAsset[] | QueryOutput<ApiPlaylistAsset>,
): ApiPlaylistAsset | ApiPlaylistAsset[] | QueryOutput<ApiPlaylistAsset> => {
  if (isPlaylistAssetQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(normalizer);
    });
  }

  return isPlaylistAssetArray(entityOrEntities) ? entityOrEntities.map(normalizer) : normalizer(entityOrEntities);
}) as {
  (playlistAsset: ApiPlaylistAsset): ApiPlaylistAsset;
  (playlistAssets: ApiPlaylistAsset[]): ApiPlaylistAsset[];
  (playlistAssetsQueryOutput: QueryOutput<ApiPlaylistAsset>): QueryOutput<ApiPlaylistAsset>;
};

const apiToModelNormalizer = (entity: PlaylistAsset | ApiPlaylistAsset): PlaylistAsset => {
  if (!isApiPlaylistAsset(entity)) {
    return entity;
  }

  return produce(normalizeBase(entity), (draft: SetOptional<ApiPlaylistAsset, "asset">) => {
    delete draft.asset;
  });
};

export const apiToModelNormalize = ((
  entityOrEntities:
    | PlaylistAsset
    | PlaylistAsset[]
    | QueryOutput<PlaylistAsset>
    | ApiPlaylistAsset
    | ApiPlaylistAsset[]
    | QueryOutput<ApiPlaylistAsset>,
): PlaylistAsset | PlaylistAsset[] | QueryOutput<PlaylistAsset> => {
  if (isPlaylistAssetQueryOutput(entityOrEntities)) {
    return produce(entityOrEntities, draft => {
      draft.items = entityOrEntities.items.map(item => apiToModelNormalizer(item) as ApiPlaylistAsset);
    });
  }

  return isPlaylistAssetArray(entityOrEntities)
    ? entityOrEntities.map(apiToModelNormalizer)
    : apiToModelNormalizer(entityOrEntities);
}) as {
  (playlistAsset: PlaylistAsset): PlaylistAsset;
  (playlistAsset: PlaylistAsset[]): PlaylistAsset[];
  (playlistAsset: QueryOutput<ApiPlaylistAsset>): QueryOutput<PlaylistAsset>;
  (playlistAsset: ApiPlaylistAsset): PlaylistAsset;
  (playlistAssets: ApiPlaylistAsset[]): PlaylistAsset[];
  (playlistAssetsQueryOutput: QueryOutput<ApiPlaylistAsset>): QueryOutput<PlaylistAsset>;
};
