import { useCallback, type FC } from "react";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TextLink } from "@ds/components/text-link";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectInvitationsIsLoading, selectSelectedInvitations, selectTableInvitations } from "../redux/selectors";
import { invitationsActions, InvitationsRemoteOperation } from "../redux/slice";
import { getInvitationStatusIndicator } from "./common/status-indicator";

const getEmailBodyTemplate = (invitation: Invitation) => (
  <TextLink to={composeDetailsRouteByEntityType(invitation)}>{invitation.email}</TextLink>
);

export const InvitationsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedInvitations);
  const entities = useRootSelector(selectTableInvitations);

  const isLoading = useRootSelector(selectInvitationsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectInvitationsIsLoading(state, InvitationsRemoteOperation.CHECKBOXES),
  );

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Invitations);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Invitations);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (invitation: Invitation) => dispatch(invitationsActions.toggleSelected(invitation.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (invitation: Invitation) => dispatch(invitationsActions.selectInvitations([invitation])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(invitationsActions.fetchInvitations(undefined, undefined, InvitationsRemoteOperation.CHECKBOXES));
      } else {
        dispatch(invitationsActions.selectInvitations([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        <Column header="Email" body={getEmailBodyTemplate} style={{ flexGrow: 2 }} />
        <Column field="user_role" header="User Role" className="w200" resizeable={false} sortable />

        {getStatusColumn({ field: "status", body: getInvitationStatusIndicator })}
        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
