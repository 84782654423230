import { produce } from "immer";
import { cloneDeep } from "lodash";
import { replace } from "redux-first-history";
import { channel, type Channel } from "redux-saga";
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
  type SagaReturnType,
} from "redux-saga/effects";

import { playlistAssetsService } from "@ds/modules/content/playlist-assets/utils/api";
import { selectPlaylistById } from "@ds/modules/content/playlists/redux/selectors";
import { playlistsActions } from "@ds/modules/content/playlists/redux/slice";
import { iotEventPlaylistAssetUpdate } from "@ds/modules/iot/redux/actions";
import { toastShowErrorAction, toastShowSuccessAction } from "@ds/modules/notifications/redux/actions";
import { selectTableData } from "@ds/modules/table-data/redux/selectors";
import { convertToApiQueryInfo } from "@ds/modules/table-data/utils/common";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnexpectedError } from "@ds/utils/errors";
import { logger } from "@ds/utils/logger";
import { UNLIMITED_PAGINATION } from "@ds/utils/query";
import { takeLatestOrEvery, takeLeadingOrEvery } from "@ds/utils/saga-helpers";
import { isErrorLike } from "@ds/utils/type-guards/error-guards";

import { createContentAsset } from "../../assets/redux/sagas/content-assets";
import { type ContentAssetsProgressChannelEvent } from "../../assets/redux/slice";
import { createPlaylistAssetModel, isApiPlaylistAssetQueryOutput } from "../utils/model";
import { normalize } from "../utils/normalizer";
import { selectPlaylistAssetsByPlaylistId, selectTablePlaylistAssets } from "./selectors/common-selectors";
import { playlistAssetsActions } from "./slice";

function* fetchPlaylistAssets({ meta }: ReturnType<typeof playlistAssetsActions.fetchPlaylistAssets>) {
  try {
    const { sorting, queryInfo, isFetchedAlready } = selectTableData(
      yield select(),
      meta.options.tableType || DetailsTableDataTypeEnum.ContentPlaylistAssets,
      meta.filters?.playlistId?.toString(),
      meta.filters,
    );

    const newMeta = cloneDeep(meta);
    const resultFromCache: QueryOutput<PlaylistAsset> = { items: [] };

    if (!newMeta.options.cache?.disableCache) {
      if (isFetchedAlready && newMeta.options.tableType) {
        resultFromCache.items = selectTablePlaylistAssets(yield select(), newMeta.options.tableType);
        newMeta.options.cache = {
          ...newMeta.options.cache,
          fetchedFromCache: true,
        };
      } else if (newMeta.filters.playlistId && Object.keys(newMeta.filters).length === 1) {
        const playlist = selectPlaylistById(yield select(), newMeta.filters.playlistId);
        resultFromCache.items = selectPlaylistAssetsByPlaylistId(yield select(), [newMeta.filters.playlistId]);
        if (resultFromCache.items.length === playlist.playlist_items_count) {
          newMeta.options.cache = {
            ...newMeta.options.cache,
            fetchedFromCache: true,
          };
        }
      }
    }

    let result: QueryOutput<PlaylistAsset> | QueryOutput<ApiPlaylistAsset> = newMeta.options.cache?.fetchedFromCache
      ? resultFromCache
      : yield call(
          [playlistAssetsService, playlistAssetsService.getPlaylistAssets],
          convertToApiQueryInfo(sorting, queryInfo),
          UNLIMITED_PAGINATION,
        );

    result = isApiPlaylistAssetQueryOutput(result) ? normalize(result) : result;
    result = newMeta.options.tableType ? result : { items: result.items };

    yield put(playlistAssetsActions.fetchPlaylistAssetsSucceeded(result, newMeta));
  } catch (err) {
    const errorTitle = "Fetch playlist assets";
    if (isErrorLike(err)) {
      yield put(playlistAssetsActions.fetchPlaylistAssetsFailed(meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* createPlaylistAsset({ payload, meta }: ReturnType<typeof playlistAssetsActions.createPlaylistAsset>) {
  try {
    const aggregatedPlaylist = selectPlaylistById(yield select(), meta.filters.playlistId || 0);
    const result: SagaReturnType<typeof playlistAssetsService.createPlaylistAsset> = yield call(
      [playlistAssetsService, playlistAssetsService.createPlaylistAsset],
      produce(payload, draft => {
        draft.playlist_id = aggregatedPlaylist.id;
        draft.playlist_order = -1;
      }),
    );

    yield put(playlistAssetsActions.createPlaylistAssetSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Playlist asset was added successfully"));
    yield put(playlistAssetsActions.selectPlaylistAssets([result]));

    yield put(
      playlistAssetsActions.fetchPlaylistAssets(meta.filters, {
        tableType: DetailsTableDataTypeEnum.ContentPlaylistAssets,
      }),
    );
  } catch (err) {
    const errorTitle = "Add playlist asset";
    if (isErrorLike(err)) {
      yield put(playlistAssetsActions.createPlaylistAssetFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* deletePlaylistAssets({ payload, meta }: ReturnType<typeof playlistAssetsActions.deletePlaylistAssets>) {
  try {
    const aggregatedPlaylist = selectPlaylistById(yield select(), meta.filters.playlistId || 0);
    if (!aggregatedPlaylist) {
      return;
    }

    yield call([playlistAssetsService, playlistAssetsService.deletePlaylistAssets], payload);
    yield put(playlistAssetsActions.deletePlaylistAssetsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Playlist asset(s) were deleted successfully"));

    if (meta.options?.redirectTo) {
      yield put(replace(meta.options.redirectTo));
    }

    yield put(playlistsActions.fetchPlaylist(meta.filters.playlistId || 0));
    yield put(playlistAssetsActions.fetchPlaylistAssets(meta.filters));
  } catch (err) {
    const errorTitle = "Delete playlist asset(s)";
    if (isErrorLike(err)) {
      yield put(playlistAssetsActions.deletePlaylistAssetsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* reorderPlaylistAsset({ payload, meta }: ReturnType<typeof playlistAssetsActions.reorderPlaylistAssets>) {
  try {
    const entities = selectTablePlaylistAssets(yield select());
    const currentAsset = entities[payload.currIndex];
    const offset = payload.newIndex - payload.currIndex;

    const aggregatedPlaylist = selectPlaylistById(yield select(), meta.filters.playlistId || 0);
    if (!aggregatedPlaylist) {
      return;
    }

    yield call([playlistAssetsService, playlistAssetsService.updatePlaylistAssetOrder], [currentAsset.id], offset);

    yield put(playlistAssetsActions.reorderPlaylistAssetsSucceeded(payload, meta));
    yield put(toastShowSuccessAction("Playlist asset was updated successfully"));

    yield put(
      playlistAssetsActions.fetchPlaylistAssets(
        { playlistId: aggregatedPlaylist.id },
        { tableType: DetailsTableDataTypeEnum.ContentPlaylistAssets, cache: { disableCache: true } },
      ),
    );
  } catch (err) {
    const errorTitle = "Reorder playlist asset";
    if (isErrorLike(err)) {
      yield put(playlistAssetsActions.reorderPlaylistAssetsFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* uploadPlaylistAsset(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof playlistAssetsActions.uploadPlaylistAsset>,
) {
  try {
    const createdAsset: SagaReturnType<typeof createContentAsset> = yield call(
      createContentAsset,
      progressChannel,
      payload,
    );

    const result: ApiPlaylistAsset = {
      ...createPlaylistAssetModel({
        asset_id: createdAsset.id,
        playlist_id: meta.filters?.playlistId || 0,
        playlist_order: 0,
      }),
      asset: createdAsset,
    };

    yield put(playlistAssetsActions.uploadPlaylistAssetSucceeded(normalize(result), meta));
    yield put(toastShowSuccessAction("Playlist asset was successfully uploaded"));

    yield call(createPlaylistAsset, playlistAssetsActions.createPlaylistAsset(result, meta.filters));
  } catch (err) {
    const errorTitle = "Upload playlist asset";
    if (isErrorLike(err)) {
      yield put(playlistAssetsActions.uploadPlaylistAssetFailed(payload, meta, err));
      yield put(toastShowErrorAction(err, errorTitle));
    } else {
      logger.error(`${errorTitle}: ${UnexpectedError}`);
      yield put(toastShowErrorAction(UnexpectedError, errorTitle));
    }
  }
}

function* uploadPlaylistAssets(
  progressChannel: Channel<ContentAssetsProgressChannelEvent>,
  { payload, meta }: ReturnType<typeof playlistAssetsActions.uploadPlaylistAssets>,
) {
  yield all(
    payload.map(entity =>
      call(uploadPlaylistAsset, progressChannel, playlistAssetsActions.uploadPlaylistAsset(entity, meta.filters)),
    ),
  );

  yield put(playlistAssetsActions.fetchPlaylistAssets(meta.filters));
}

function* refetchPlaylistAssets({ payload }: ReturnType<typeof iotEventPlaylistAssetUpdate>) {
  yield put(playlistAssetsActions.iotUpdate(payload));
}

function* handleUploading() {
  const progressChannel: Channel<ContentAssetsProgressChannelEvent> = yield call(channel);
  yield takeEvery(playlistAssetsActions.uploadPlaylistAssets, uploadPlaylistAssets, progressChannel);

  while (true) {
    const { progressInfo }: ContentAssetsProgressChannelEvent = yield take(progressChannel);
    yield put(playlistAssetsActions.progressUploadingPlaylistAsset(progressInfo));
  }
}

export function* watchPlaylistAssets() {
  yield takeLatestOrEvery(playlistAssetsActions.fetchPlaylistAssets, function* (action) {
    yield race({
      task: call(fetchPlaylistAssets, action),
      cancel: take([playlistAssetsActions.selectPlaylistAssets, playlistAssetsActions.toggleSelected]),
    });
  });

  yield takeLeadingOrEvery(playlistAssetsActions.createPlaylistAsset, createPlaylistAsset);
  yield takeLeading(playlistAssetsActions.deletePlaylistAssets, deletePlaylistAssets);
  yield takeLeading(playlistAssetsActions.reorderPlaylistAssets, reorderPlaylistAsset);
  yield takeLatest(iotEventPlaylistAssetUpdate, refetchPlaylistAssets);

  yield call(handleUploading);
}
