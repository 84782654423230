import { useCallback, type FC } from "react";
import styled from "styled-components";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TrimmedContent } from "@ds/components/trimmed-text";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";

import { selectPlaylistsIsLoading, selectSelectedPlaylists, selectTablePlaylists } from "../redux/selectors";
import { PlaylistsRemoteOperation, playlistsActions } from "../redux/slice";
import { isPlaylistSupportAudio, isPlaylistSupportImages, isPlaylistSupportVideos } from "../utils/helpers";

const ContentAssetIcon = styled.i`
  font-size: 1.4rem;
  margin: 0 10px;
  color: var(--textColor060);
`;

const getDescriptionBodyTemplate = (playlist: Playlist) => <TrimmedContent content={playlist.description} />;

const renderVideoIcon = () => (
  <TrimmedContent content="videos">
    <ContentAssetIcon title="videos" className="pi pi-video" />
  </TrimmedContent>
);

const renderImageIcon = () => (
  <TrimmedContent content="images">
    <ContentAssetIcon className="pi pi-image" />
  </TrimmedContent>
);

const renderAudioIcon = () => (
  <TrimmedContent content="audio">
    <ContentAssetIcon title="audio" className="pi pi-volume-up" />
  </TrimmedContent>
);

const getMediaTypesBodyTemplate = (playlist: Playlist) => (
  <div className="flex justify-content-center">
    {isPlaylistSupportVideos(playlist) && renderVideoIcon()}
    {isPlaylistSupportImages(playlist) && renderImageIcon()}
    {isPlaylistSupportAudio(playlist) && renderAudioIcon()}
  </div>
);

const getTotalAssetsBodyTemplate = (playlist: Playlist) => playlist.playlist_items_count;

export const PlaylistsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedPlaylists);
  const entities = useRootSelector(selectTablePlaylists);

  const isLoading = useRootSelector(selectPlaylistsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectPlaylistsIsLoading(state, PlaylistsRemoteOperation.CHECKBOXES),
  );

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.ContentPlaylists);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.ContentPlaylists);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (playlist: Playlist) => dispatch(playlistsActions.toggleSelected(playlist.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (playlist: Playlist) => {
      dispatch(playlistsActions.selectPlaylists([playlist]));
    },
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(playlistsActions.fetchPlaylists(undefined, undefined, PlaylistsRemoteOperation.CHECKBOXES));
      } else {
        dispatch(playlistsActions.selectPlaylists([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 2 } })}

        <Column
          field="description"
          header="Description"
          body={getDescriptionBodyTemplate}
          style={{ flexGrow: 3 }}
          resizeable={false}
          sortable
        />

        <Column
          field="media_types"
          header="Media Types"
          body={getMediaTypesBodyTemplate}
          className="center w175"
          resizeable={false}
          sortable
        />

        <Column
          field="playlist_items_count"
          header="# Assets"
          body={getTotalAssetsBodyTemplate}
          className="center w175"
          resizeable={false}
          sortable
        />

        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
