import { useCallback, type FC } from "react";

import { useRootSelector } from "@ds/hooks/redux-hooks";

import { selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { usePagination, useRootDispatch, useTableDataSorting } from "@ds/hooks";

import { selectLocationsIsLoading, selectSelectedLocations, selectTableLocations } from "../redux/selectors";
import { LocalitiesRemoteOperation, locationsActions } from "../redux/slice";

export const LocationsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedLocations);
  const entities = useRootSelector(selectTableLocations);

  const isLoading = useRootSelector(selectLocationsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectLocationsIsLoading(state, LocalitiesRemoteOperation.CHECKBOXES),
  );

  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Locations);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Locations);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (location: Locality) => dispatch(locationsActions.toggleSelected(location.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (location: Locality) => {
      dispatch(locationsActions.selectLocations([location]));
    },
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(locationsActions.fetchLocations(undefined, undefined, LocalitiesRemoteOperation.CHECKBOXES));
      } else {
        dispatch(locationsActions.selectLocations([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading || isDeploying}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading || isDeploying}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 4 } })}
        <Column field="store_id" header="Store ID" resizeable={false} sortable />

        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
