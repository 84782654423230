import { useCallback, type FC } from "react";

import { selectDeploymentsIsLoading } from "@ds/modules/deployments/redux/selectors";
import { DeploymentsRemoteOperation } from "@ds/modules/deployments/redux/slice";
import { selectLocationById } from "@ds/modules/locations/redux/selectors";
import { selectTemplateExperienceTableData } from "@ds/modules/settings/projects/redux/selectors/experience-formation-selectors";
import { selectCurrentUserProjectId } from "@ds/modules/settings/users/redux/selectors";
import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { UnassignedLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
  getStatusColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TextLink } from "@ds/components/text-link";
import { useDeepRootSelector, usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { selectExperiencesIsLoading, selectSelectedExperiences, selectTableExperiences } from "../redux/selectors";
import { ExperiencesRemoteOperation, experiencesActions } from "../redux/slice";
import { getExperienceStatusIndicator } from "./common/status-indicator";

const useGetLocationNameBodyTemplate = (experience: Experience) => {
  const location = useRootSelector(state => selectLocationById(state, experience.location_id));
  return <TextLink to={composeDetailsRouteByEntityType(location)}>{location?.name || <UnassignedLabel />}</TextLink>;
};

export const ExperiencesTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedExperiences);
  const entities = useRootSelector(selectTableExperiences);
  const projectId = useRootSelector(selectCurrentUserProjectId);
  const formationColumns = useDeepRootSelector(state => selectTemplateExperienceTableData(state, projectId));

  const isLoading = useRootSelector(selectExperiencesIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectExperiencesIsLoading(state, ExperiencesRemoteOperation.CHECKBOXES),
  );

  const isDeploying = useRootSelector(state => selectDeploymentsIsLoading(state, DeploymentsRemoteOperation.DEPLOYING));

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Experiences);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Experiences);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (experience: Experience) => dispatch(experiencesActions.toggleSelected(experience.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (experience: Experience) => {
      dispatch(experiencesActions.selectExperiences([experience]));
    },
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(experiencesActions.fetchExperiences(undefined, undefined, ExperiencesRemoteOperation.CHECKBOXES));
      } else {
        dispatch(experiencesActions.selectExperiences([]));
      }
    },
    [dispatch],
  );

  const lastElementIndex = formationColumns.length - 1;
  return (
    <Loading isLoading={isLoading || isDeploying}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading || isDeploying}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 4 } })}
        <Column header="Location" body={useGetLocationNameBodyTemplate} style={{ flexGrow: 3 }} />

        {formationColumns.map((column, i) => (
          <Column
            key={`experience-formation-column-${column.field}`}
            field={column.field}
            header={column.title}
            body={({ formation: { properties } }: Experience) => properties[column.field]?.value}
            style={{ flexBasis: "75px" }}
            resizeable={i !== lastElementIndex}
          />
        ))}

        {getStatusColumn({ body: getExperienceStatusIndicator })}
        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
