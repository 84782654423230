import { useCallback, type FC } from "react";

import { MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { YesNoCheckbox } from "@ds/components/forms/inputs";
import { Loading } from "@ds/components/loaders/loading";
import { TableWithPagination } from "@ds/components/table/main-table";
import {
  Column,
  getEntityIdColumn,
  getEntityNameColumn,
  getGoToDetailsColumn,
  getLastUpdatedColumn,
  getSelectionColumn,
} from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { TrimmedContent } from "@ds/components/trimmed-text";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";

import {
  selectProjectsIsLoading,
  selectSelectedProjects,
  selectTableProjects,
} from "../redux/selectors/common-selectors";
import { ProjectsRemoteOperation, projectsActions } from "../redux/slice";

const getDescriptionBodyTemplate = (project: Project) => <TrimmedContent content={project.description} />;
const getIsActiveBodyTemplate = (project: Project) => <YesNoCheckbox isChecked={project.is_active} />;
const getIsAutoPairingBodyTemplate = (project: Project) => (
  <YesNoCheckbox isChecked={project.is_auto_pairing_enabled} />
);

const getIsNotificationsBodyTemplate = (project: Project) => (
  <YesNoCheckbox isChecked={project.is_notifications_enabled} />
);

export const ProjectsTable: FC = () => {
  const selectedEntities = useRootSelector(selectSelectedProjects);
  const entities = useRootSelector(selectTableProjects);

  const isLoading = useRootSelector(selectProjectsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectProjectsIsLoading(state, ProjectsRemoteOperation.CHECKBOXES),
  );

  const [pagination, setPagination] = usePagination(MainTableDataTypeEnum.Projects);
  const [{ sortOrder, sortField }, setSorting] = useTableDataSorting(MainTableDataTypeEnum.Projects);

  const dispatch = useRootDispatch();
  const onToggleRowSelected = useCallback(
    (project: Project) => dispatch(projectsActions.toggleSelected(project.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (project: Project) => dispatch(projectsActions.selectProjects([project])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(projectsActions.fetchProjects(undefined, undefined, ProjectsRemoteOperation.CHECKBOXES));
      } else {
        dispatch(projectsActions.selectProjects([]));
      }
    },
    [dispatch],
  );

  return (
    <Loading isLoading={isLoading}>
      <TableWithPagination
        value={entities}
        selection={selectedEntities}
        onSingleRowSelect={onSingleRowSelectHandler}
        toggleRowSelected={onToggleRowSelected}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={setSorting}
        pagination={pagination}
        onPageChange={setPagination}
      >
        {getSelectionColumn({
          total: pagination.total,
          selected: selectedEntities.length,
          isLoading: checkboxIsLoading,
          onToggleAll: onToggleAllHandler,
        })}

        {getEntityIdColumn()}
        {getEntityNameColumn({ style: { flexGrow: 2 } })}

        <Column
          field="description"
          header="Description"
          body={getDescriptionBodyTemplate}
          style={{ flexGrow: 3 }}
          resizeable={false}
          sortable
        />

        <Column
          field="is_active"
          header="Active"
          body={getIsActiveBodyTemplate}
          className="center w150"
          resizeable={false}
          sortable
        />

        <Column
          field="is_auto_pairing_enabled"
          header="Auto-pairing"
          body={getIsAutoPairingBodyTemplate}
          className="center w150"
          resizeable={false}
          sortable
        />

        <Column
          field="is_notifications_enabled"
          header="Notifications"
          body={getIsNotificationsBodyTemplate}
          className="center w150"
          resizeable={false}
          sortable
        />

        {getLastUpdatedColumn()}
        {getGoToDetailsColumn()}
      </TableWithPagination>

      <TotalSelectedLabel selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length} />
    </Loading>
  );
};
