import prettyBytes from "pretty-bytes";
import { useCallback, useEffect, type FC } from "react";

import { useVisibility } from "@ds/hooks/use-visibility";

import { isAggregatedEntity } from "@ds/model/entity-model";

import { ContentAssetThumbnail } from "@ds/modules/content/assets/components/common/content-asset-thumbnail";
import { selectContentAssetById } from "@ds/modules/content/assets/redux/selectors/common-selectors";
import { UploadFilesModal } from "@ds/modules/content/components/common/upload-files-modal";
import { CreatePlaylistAssetModal } from "@ds/modules/content/playlist-assets/components/forms/create-playlist-asset-modal";
import {
  selectPlaylistAssetsIsLoading,
  selectSelectedPlaylistAssets,
  selectTablePlaylistAssets,
} from "@ds/modules/content/playlist-assets/redux/selectors/common-selectors";
import { PlaylistAssetsRemoteOperation, playlistAssetsActions } from "@ds/modules/content/playlist-assets/redux/slice";
import { DEFAULT_TABLE_TAB } from "@ds/modules/table-data/redux/slice";
import { DetailsTableDataTypeEnum, MainTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { DetailsCard } from "@ds/components/details-view";
import { DndDropzone } from "@ds/components/dnd-dropzone";
import { MultipleValuesLabel } from "@ds/components/forms/labels/common";
import { Loading } from "@ds/components/loaders/loading";
import { DataTableRowReorderEvent } from "@ds/components/table/data-table";
import { DetailsTable } from "@ds/components/table/details-table";
import { Column, getLastUpdatedColumn, getReorderColumn, getSelectionColumn } from "@ds/components/table/table-columns";
import { TotalSelectedLabel } from "@ds/components/table/total-selected-label";
import { LAST_UPDATED_COLUMN_FIELD } from "@ds/constants/table";
import { usePagination, useRootDispatch, useRootSelector, useTableDataSorting } from "@ds/hooks";

import { getSupportedMediaTypes } from "../../utils/helpers";
import { PlaylistDetailsAssetsCardActionsMenu } from "./playlist-details-assets-card-actions-menu";

const useGetThumbnailBodyTemplate = (playlistAsset: PlaylistAsset) => {
  const asset = useRootSelector(state => selectContentAssetById(state, playlistAsset?.asset_id || 0));
  return <ContentAssetThumbnail asset={asset} />;
};

const useGetAssetNameBodyTemplate = (playlistAsset: PlaylistAsset) => {
  const asset = useRootSelector(state => selectContentAssetById(state, playlistAsset?.asset_id || 0));
  return asset.name;
};

const useGetAssetContentTypeBodyTemplate = (playlistAsset: PlaylistAsset) => {
  const asset = useRootSelector(state => selectContentAssetById(state, playlistAsset?.asset_id || 0));
  return asset.content_type;
};

const useGetAssetFilesizeBodyTemplate = (playlistAsset: PlaylistAsset) => {
  const asset = useRootSelector(state => selectContentAssetById(state, playlistAsset?.asset_id || 0));
  return prettyBytes(asset.file_size);
};

export const PlaylistDetailsAssetsCard: FC<{ playlist: Playlist }> = ({ playlist }) => {
  const isAggregated = isAggregatedEntity(playlist);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useVisibility();

  const selectedEntities = useRootSelector(selectSelectedPlaylistAssets);
  const entities = useRootSelector(selectTablePlaylistAssets);

  const isLoading = useRootSelector(selectPlaylistAssetsIsLoading);
  const checkboxIsLoading = useRootSelector(state =>
    selectPlaylistAssetsIsLoading(state, PlaylistAssetsRemoteOperation.CHECKBOXES),
  );

  const [pagination] = usePagination(
    DetailsTableDataTypeEnum.ContentPlaylistAssets,
    isAggregated ? DEFAULT_TABLE_TAB : playlist.id.toString(),
    {
      playlistId: playlist.id,
    },
  );

  const [{ sortOrder, sortField }] = useTableDataSorting(
    DetailsTableDataTypeEnum.ContentPlaylistAssets,
    isAggregated ? DEFAULT_TABLE_TAB : playlist.id.toString(),
    {
      playlistId: playlist.id,
    },
  );

  const dispatch = useRootDispatch();
  useEffect(() => {
    if (!isAggregated) {
      dispatch(
        playlistAssetsActions.fetchPlaylistAssets(
          { playlistId: playlist.id },
          { tableType: DetailsTableDataTypeEnum.ContentPlaylistAssets },
        ),
      );
    }
  }, [dispatch, isAggregated, playlist.id]);

  const onReorderHandler = useCallback(
    (currIndex: number, newIndex: number) => {
      if (currIndex !== newIndex) {
        console.log("onReorderHandler", currIndex, newIndex);
        dispatch(playlistAssetsActions.reorderPlaylistAssets({ currIndex, newIndex }, { playlistId: playlist.id }));
      }
    },
    [dispatch, playlist.id],
  );

  const onTableRowReorderHandler = useCallback(
    (e: DataTableRowReorderEvent<PlaylistAsset[]>) => {
      onReorderHandler(e.dragIndex, e.dropIndex);
    },
    [onReorderHandler],
  );

  const onToggleRowSelected = useCallback(
    (playlistAsset: PlaylistAsset) => dispatch(playlistAssetsActions.toggleSelected(playlistAsset.id)),
    [dispatch],
  );

  const onSingleRowSelectHandler = useCallback(
    (playlistAsset: PlaylistAsset) => dispatch(playlistAssetsActions.selectPlaylistAssets([playlistAsset])),
    [dispatch],
  );

  const onToggleAllHandler = useCallback(
    (isSelectedAll: boolean) => {
      if (isSelectedAll) {
        dispatch(
          playlistAssetsActions.fetchPlaylistAssets(
            { playlistId: playlist.id },
            undefined,
            PlaylistAssetsRemoteOperation.CHECKBOXES,
          ),
        );
      } else {
        dispatch(playlistAssetsActions.selectPlaylistAssets([]));
      }
    },
    [dispatch, playlist.id],
  );

  const onUploadHandler = useCallback(
    (files: File[]) => {
      if (files.length) {
        dispatch(playlistAssetsActions.uploadPlaylistAssets(files, { playlistId: playlist.id }));
      }
    },
    [dispatch, playlist.id],
  );

  const setIsMinimized = useCallback(
    (value: boolean) => () => dispatch(playlistAssetsActions.setUploadModalMinimized(value)),
    [dispatch],
  );

  return (
    <DetailsCard
      title="Assets"
      icon="video"
      actions={
        !isAggregated && (
          <PlaylistDetailsAssetsCardActionsMenu
            playlist={playlist}
            onCreate={setIsUpdateModalVisible(true)}
            onUpload={onUploadHandler}
            onReorder={onReorderHandler}
          />
        )
      }
    >
      <Loading isLoading={isLoading}>
        {!isAggregated && (
          <DndDropzone acceptMediaTypes={getSupportedMediaTypes(playlist)} onUpload={onUploadHandler}>
            <DetailsTable
              value={entities}
              className="playlist-assets-table"
              selection={selectedEntities}
              onSingleRowSelect={onSingleRowSelectHandler}
              toggleRowSelected={onToggleRowSelected}
              loading={isLoading}
              sortField={sortField}
              sortOrder={sortOrder}
              onRowReorder={onTableRowReorderHandler}
            >
              {getReorderColumn({ rowReorder: selectedEntities.length < 2 })}
              {getSelectionColumn({
                total: pagination.total,
                selected: selectedEntities.length,
                isLoading: checkboxIsLoading,
                onToggleAll: onToggleAllHandler,
              })}

              <Column header="File Name" body={useGetAssetNameBodyTemplate} resizeable={false} />
              <Column
                header="Thumbnail"
                body={useGetThumbnailBodyTemplate}
                className="center w200"
                resizeable={false}
              />
              <Column
                header="Media Type"
                body={useGetAssetContentTypeBodyTemplate}
                className="center w150"
                resizeable={false}
              />
              <Column
                header="File Size"
                body={useGetAssetFilesizeBodyTemplate}
                className="center w150"
                resizeable={false}
              />

              {getLastUpdatedColumn(LAST_UPDATED_COLUMN_FIELD, false)}
            </DetailsTable>

            <TotalSelectedLabel
              selectedCount={checkboxIsLoading ? Number(pagination.total) : selectedEntities.length}
            />
          </DndDropzone>
        )}

        {isAggregated && <MultipleValuesLabel />}
      </Loading>

      <UploadFilesModal tableType={MainTableDataTypeEnum.ContentPlaylists} setIsMinimized={setIsMinimized} />

      {isUpdateModalVisible && (
        <CreatePlaylistAssetModal playlist={playlist} onClose={setIsUpdateModalVisible(false)} />
      )}
    </DetailsCard>
  );
};
