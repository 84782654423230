import {
  InputNumber as PrimeInputNumber,
  type InputNumberValueChangeEvent as PrimeInputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { type FC, type FocusEventHandler, type KeyboardEventHandler, type Ref } from "react";
import styled from "styled-components";

import { TextInputMixin } from "./styles";

export const StyledNumber = styled(PrimeInputNumber)<{ err?: Error }>`
  ${TextInputMixin}

  border: none;
`;

export const InputNumber: FC<{
  inputRef?: Ref<PrimeInputNumber>;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: number;
  min?: number;
  max?: number;
  prefix?: string;
  suffix?: string;
  className?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  onChange?: (e: PrimeInputNumberValueChangeEvent) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLSpanElement>;
}> = ({
  inputRef,
  id,
  name,
  placeholder = "",
  value,
  min,
  max,
  prefix,
  suffix,
  className = "",
  autoFocus,
  isDisabled = false,
  isReadonly = false,
  onChange,
  onBlur,
  onKeyPress,
}) => (
  <StyledNumber
    ref={inputRef}
    inputId={id}
    name={name}
    placeholder={placeholder}
    value={value}
    min={min}
    max={max}
    prefix={prefix}
    suffix={suffix}
    showButtons
    useGrouping={false}
    locale="en-US"
    className={className}
    autoFocus={autoFocus}
    aria-disabled={isDisabled || isReadonly}
    disabled={isDisabled}
    onValueChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
  />
);
