import { useCallback, type FC } from "react";

import { ContentAssetUploadInput } from "@ds/modules/content/assets/components/forms/common/content-assets-upload-input";
import { useDropzoneRef } from "@ds/modules/content/assets/hooks/use-dropzone-ref";
import {
  selectAggregatedPlaylistAssetIndexForSelectedEntities,
  selectPlaylistAssetsIsLoading,
  selectSelectedPlaylistAssetIds,
  selectTablePlaylistAssets,
} from "@ds/modules/content/playlist-assets/redux/selectors/common-selectors";
import { playlistAssetsActions } from "@ds/modules/content/playlist-assets/redux/slice";
import { DetailsTableDataTypeEnum } from "@ds/modules/table-data/utils/model";

import { ActionsBar } from "@ds/components/actions-bar";
import { Button, ButtonActionType, ButtonType } from "@ds/components/forms/buttons";
import { IconType } from "@ds/components/icons";
import { confirmWarning } from "@ds/components/popups/confirmation-dialog";
import { useDeepRootSelector, useRootDispatch, useRootSelector } from "@ds/hooks";
import { pluralizeDeleteAssetsMessage } from "@ds/utils/localization/content-assets";

import { getSupportedMediaTypes } from "../../utils/helpers";

export const PlaylistDetailsAssetsCardActionsMenu: FC<{
  playlist: Playlist;
  onCreate: () => void;
  onUpload: (files: File[]) => void;
  onReorder: (currIndex: number, newIndex: number) => void;
}> = ({ playlist, onCreate, onUpload, onReorder }) => {
  const isLoading = useRootSelector(selectPlaylistAssetsIsLoading);
  const playlistAssets = useRootSelector(selectTablePlaylistAssets);
  const selectedPlaylistAssetIds = useRootSelector(selectSelectedPlaylistAssetIds);
  const selectedPlaylistAssets = useRootSelector(selectSelectedPlaylistAssetIds);
  const aggregatedPlaylistAssetIndex = useDeepRootSelector(state =>
    selectAggregatedPlaylistAssetIndexForSelectedEntities(
      state,
      DetailsTableDataTypeEnum.ContentPlaylistAssets,
      "playlist_order",
    ),
  );

  const [dropzoneRef, openDropzone] = useDropzoneRef();

  const dispatch = useRootDispatch();
  const onRefreshClickHandler = useCallback(
    () =>
      dispatch(
        playlistAssetsActions.fetchPlaylistAssets(
          { playlistId: playlist.id },
          {
            tableType: DetailsTableDataTypeEnum.ContentPlaylistAssets,
            cache: { disableCache: true },
          },
        ),
      ),
    [dispatch, playlist.id],
  );

  const onDeleteClickHandler = useCallback(
    () =>
      confirmWarning({
        message: pluralizeDeleteAssetsMessage(selectedPlaylistAssetIds.length),
        accept: () =>
          dispatch(playlistAssetsActions.deletePlaylistAssets(selectedPlaylistAssetIds, { playlistId: playlist.id })),
      }),
    [dispatch, selectedPlaylistAssetIds, playlist.id],
  );

  const onMoveAssetUpHandler = useCallback(
    () => onReorder(aggregatedPlaylistAssetIndex, aggregatedPlaylistAssetIndex - 1),
    [aggregatedPlaylistAssetIndex, onReorder],
  );

  const onMoveAssetDownHandler = useCallback(
    () => onReorder(aggregatedPlaylistAssetIndex, aggregatedPlaylistAssetIndex + 1),
    [aggregatedPlaylistAssetIndex, onReorder],
  );

  const isReorderDisabled = isLoading || selectedPlaylistAssets.length !== 1;

  return (
    <ActionsBar>
      <Button ariaLabel="create button" buttonType={ButtonType.Round} icon={IconType.Create} onClick={onCreate} />
      <Button
        ariaLabel="delete button"
        buttonType={ButtonType.Round}
        actionType={ButtonActionType.Error}
        icon={IconType.Delete}
        isDisabled={!selectedPlaylistAssets.length}
        onClick={onDeleteClickHandler}
      />

      <Button ariaLabel="upload button" buttonType={ButtonType.Round} icon={IconType.Upload} onClick={openDropzone} />
      <Button
        ariaLabel="Move asset up"
        buttonType={ButtonType.Round}
        icon={IconType.SortUp}
        isDisabled={isReorderDisabled || aggregatedPlaylistAssetIndex < 1}
        onClick={onMoveAssetUpHandler}
      />

      <Button
        ariaLabel="Move asset down"
        buttonType={ButtonType.Round}
        icon={IconType.SortDown}
        isDisabled={isReorderDisabled || aggregatedPlaylistAssetIndex >= playlistAssets.length - 1}
        onClick={onMoveAssetDownHandler}
      />

      <Button
        ariaLabel="refresh button"
        buttonType={ButtonType.Round}
        icon={IconType.Refresh}
        onClick={onRefreshClickHandler}
      />

      <ContentAssetUploadInput
        acceptMediaTypes={getSupportedMediaTypes(playlist)}
        dropzoneRef={dropzoneRef}
        onUpload={onUpload}
      />
    </ActionsBar>
  );
};
