import { Column as PrimeColumn, type ColumnProps as PrimeColumnProps } from "primereact/column";
import { type FC } from "react";

import { LAST_UPDATED_COLUMN_FIELD, LAST_UPDATED_COLUMN_TITLE } from "@ds/constants/table";
import { formatRelativeDateEntity } from "@ds/utils/date";
import { composeDetailsRouteByEntityType } from "@ds/utils/router";

import { UnassignedLabel } from "../forms/labels/common";
import { TextLink } from "../text-link";
import { SelectionColumn } from "./selection-column";

export { type ColumnBodyOptions } from "primereact/column";
export { PrimeColumn as Column };

export const getSelectionColumn: FC<{
  total?: number;
  selected: number;
  isLoading: boolean;
  onToggleAll: (isSelectedAll: boolean) => void;
}> = ({ total = 0, ...rest }) => (
  <PrimeColumn
    selectionMode="multiple"
    header={<SelectionColumn total={total} {...rest} />}
    headerClassName="select-checkbox-column"
    resizeable={false}
  />
);

export const getReorderColumn = (columnProps: PrimeColumnProps = {}) => (
  <PrimeColumn className="reorder-column" rowReorder resizeable={false} {...columnProps} />
);

export const getEntityIdColumn = (columnProps: PrimeColumnProps = {}) => (
  <PrimeColumn field="id" header="id" className="nerdy-table-id" sortable hidden {...columnProps} />
);

export const getEntityNameColumn = (columnProps: PrimeColumnProps = {}) => (
  <PrimeColumn
    field="name"
    header="Name"
    body={(entity: Experience | Player | Peripheral | Locality | Deployment) => {
      const entityHref = composeDetailsRouteByEntityType(entity);
      const entityLink = entityHref ? <TextLink to={entityHref}>{entity.name}</TextLink> : <>{entity.name}</>;
      return entity.name ? entityLink : <UnassignedLabel />;
    }}
    sortable
    {...columnProps}
  />
);

export const getStatusColumn = ({
  field = "status",
  header = "Status",
  body,
}: {
  field?: string;
  header?: string;
  body?: PrimeColumnProps["body"];
}) => <PrimeColumn field={field} header={header} body={body} className="status-column" resizeable={false} sortable />;

export const getLastUpdatedColumn = (field = LAST_UPDATED_COLUMN_FIELD, isSortable = true) => (
  <PrimeColumn
    field={field}
    header={LAST_UPDATED_COLUMN_TITLE}
    body={formatRelativeDateEntity}
    className="last_updated_column"
    resizeable={false}
    sortable={isSortable}
  />
);

export const getGoToDetailsColumn = () => (
  <PrimeColumn
    resizeable={false}
    body={(entity: Entity) => (
      <TextLink to={composeDetailsRouteByEntityType(entity)}>
        <i className="pi pi-chevron-right text-lg"></i>
      </TextLink>
    )}
    className="go_to_details_column"
  />
);
