import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import {
  useForm,
  type Control,
  type DeepPartial,
  type DeepRequired,
  type FieldErrorsImpl,
  type FieldValues,
  type Path,
  type PathValue,
  type Resolver,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormHandleSubmit,
  type UseFormSetValue,
} from "react-hook-form";

import { getProxyFieldPath } from "@ds/utils/entities";
import {
  getValuesByBooleanMap,
  normalizeChangedFieldsBeforeUpdate,
  normalizeFormationFieldNameBeforeEdit,
} from "@ds/utils/forms";
import { deepKeys, hasProperty } from "@ds/utils/properties";

import { type EditableDetailsCardRowProps } from "./editable-details-card-row-decorator";

export type EditableDetailsCardChildProps<T extends FieldValues> = {
  isDirty: boolean;
  entity: T;
  control: Control<T>;
  errors: FieldErrorsImpl<DeepRequired<T>>;
  setValue: UseFormSetValue<T>;
  handleSubmit: UseFormHandleSubmit<T>;
  onResetForm: () => void;
  onSubmitForm: (data: T) => void;
} & EditableDetailsCardRowProps<T>;

export const EditableDetailsCardDecorator = <T extends FieldValues>({
  children,
  initialValue,
  validationResolver,
  onSubmit,
}: {
  children: (props: EditableDetailsCardChildProps<T>) => JSX.Element;
  initialValue: DeepPartial<T>;
  validationResolver: Resolver<T>;
  onSubmit: (entity: DeepPartial<T>) => void;
}) => {
  const [previousValue, setPreviousValue] = useState<PathValue<T, Path<T>>>(undefined as PathValue<T, Path<T>>);
  const [previousProxyValue, setPreviousProxyValue] = useState<PathValue<T, Path<T>>>(
    undefined as PathValue<T, Path<T>>,
  );

  const [isEditing, setIsEditing] = useState(false);

  const {
    control,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    resetField,
    setFocus,
    formState: { isDirty, dirtyFields, errors },
  } = useForm<T>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: initialValue,
    resolver: validationResolver,
  });

  const handleSubmit: UseFormHandleSubmit<T> = useCallback(
    (onValid: SubmitHandler<T>, onInvalid?: SubmitErrorHandler<T>) => {
      return async (e?: React.BaseSyntheticEvent) => {
        const data = getValues();
        const dirtyValues = getValuesByBooleanMap(dirtyFields, data);
        const isValid = await trigger(deepKeys(dirtyValues) as Path<T>[]);
        if (isValid) {
          onValid(data, e);
        } else if (onInvalid) {
          onInvalid(errors, e);
        }
      };
    },
    [dirtyFields, errors, getValues, trigger],
  );

  const onResetFormClickHandler = useCallback(() => reset(), [reset]);
  const onSubmitFormClickHandler = useCallback(
    (data: T) => onSubmit(normalizeChangedFieldsBeforeUpdate(getValuesByBooleanMap(dirtyFields, data))),
    [dirtyFields, onSubmit],
  );

  const onEditRowClickHandler = useCallback(
    (field: Path<T>) => () => {
      const proxyField = getProxyFieldPath(field);
      setPreviousValue(cloneDeep(getValues(field)));
      setPreviousProxyValue(cloneDeep(getValues(proxyField)));
      setIsEditing(true);

      setTimeout(() => {
        try {
          setFocus(normalizeFormationFieldNameBeforeEdit(field), { shouldSelect: true });
        } catch {
          /* there some problems with primereact accessibility support */
        }
      }, 0);
    },
    [getValues, setFocus],
  );

  const onResetRowClickHandler = useCallback(
    (field: Path<T>) => () => {
      const proxyField = getProxyFieldPath(field);
      resetField(field);
      resetField(proxyField);
    },
    [resetField],
  );

  const onCancelRowClickHandler = useCallback(
    (field: Path<T>) => () => {
      const proxyField = getProxyFieldPath(field);
      setValue(field, previousValue, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      const data = getValues();
      if (hasProperty(data, proxyField)) {
        setValue(proxyField, previousProxyValue, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }

      setIsEditing(false);
    },
    [previousValue, previousProxyValue, setValue, getValues],
  );

  const onSaveRowClickHandler = useCallback((_: Path<T>) => () => setIsEditing(false), []);

  const entity = watch();
  return children({
    entity,
    isEditing,
    isDirty,
    control,
    dirtyFields,
    errors,
    setValue,
    handleSubmit,
    onResetForm: onResetFormClickHandler,
    onSubmitForm: onSubmitFormClickHandler,
    onEditRow: onEditRowClickHandler,
    onResetRow: onResetRowClickHandler,
    onCancelRow: onCancelRowClickHandler,
    onSaveRow: onSaveRowClickHandler,
  });
};
